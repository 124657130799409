import { UIEventHandler, useEffect, useRef } from "react";
import { ConfigProvider, Input, Select, SelectProps, Spin } from "antd";
import { useQuery } from "react-query";
import { uniqBy, compact, isEqual } from "lodash";
import { QueryKeyType } from "types/query-keys";
import { DefaultOptionType } from "antd/es/select";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SelectWithSearchProps extends SelectProps {
  queryFn: (options: any) => Promise<any>;
  queryKeyObject: QueryKeyType;
  dataLabelFn?: (data: any) => string;
  initialOpts?: (Record<string, any> | undefined | null)[];
  disabled?: boolean;
  filters?: Record<string, any>;
  selectFirstOption?: boolean;
}

export const SelectWithSearch = (props: SelectWithSearchProps) => {
  const {
    queryFn,
    queryKeyObject,
    dataLabelFn = undefined,
    initialOpts = [],
    disabled = false,
    filters = {},
    onChange,
    selectFirstOption = false,
    ...selectOnlyProps
  } = props;

  const queryProps = useRef({
    search: "",
    pageNumber: 1,
    dropDownData: compact(initialOpts || []),
  });
  const valueRef = useRef<string | null>(props.value as string | null);
  const filterRef = useRef(filters || {});
  valueRef.current = props.value as string | null;

  const fetchDropDownData = async (search: string, page: number) => {
    if (disabled) {
      return [];
    }

    try {
      return queryFn({
        search,
        page,
        filters,
      }) as Promise<Record<string, any>[]>;
    } catch (_error) {
      console.log(_error);
      return [];
    }
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeyObject.paginate?.(
      { filters, search: queryProps.current.search },
      queryProps.current.pageNumber,
    ),
    queryFn: () =>
      fetchDropDownData(
        queryProps.current.search,
        queryProps.current.pageNumber,
      ),
    enabled: !disabled,
  });

  if (!isLoading && data) {
    if (isEqual(filterRef.current, filters)) {
      queryProps.current.dropDownData = uniqBy(
        [...queryProps.current.dropDownData, ...data],
        "id",
      );
    } else {
      valueRef.current = null;
      queryProps.current.dropDownData = data;
      filterRef.current = filters;
    }
  }

  const handleSearch = (keyword: string) => {
    queryProps.current = {
      ...queryProps.current,
      dropDownData: [],
      search: keyword,
      pageNumber: 1,
    };
    refetch().catch(() => {
      console.log("Error");
    });
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
    e.persist();
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      (data || []).length >= 20
    ) {
      queryProps.current = {
        ...queryProps.current,
        pageNumber: queryProps.current.pageNumber + 1,
      };
      refetch().catch(() => {
        console.log("Error");
      });
    }
  };

  const handleChange = (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    valueRef.current = value;
    onChange?.(value, option);
  };

  const handleFocus = () => {
    queryProps.current = {
      ...queryProps.current,
      search: "",
      pageNumber: 1,
    };
    refetch().catch(() => {
      console.log("Error");
    });
  };

  let selectOptions = queryProps.current
    .dropDownData as unknown as DefaultOptionType[];
  if (dataLabelFn) {
    selectOptions = queryProps.current.dropDownData.map(
      (dt: DefaultOptionType) =>
        ({
          value: dt.id as string | number,
          label: dataLabelFn(dt),
          ...dt,
        }) as DefaultOptionType,
    );
  }

  useEffect(() => {
    if (selectFirstOption && selectOptions.length > 0) {
      valueRef.current = String(selectOptions[0].value);
      onChange?.(selectOptions[0].value, selectOptions[0]);
    }
  }, [selectFirstOption, selectOptions, onChange]);

  const { componentDisabled } = ConfigProvider.useConfig();

  if (componentDisabled) {
    const initialSelectedOpt =
      initialOpts.length === 0 ? selectOptions : compact(initialOpts || []);

    let selectedOpt = initialSelectedOpt.find((opt: DefaultOptionType) => {
      if (opt.id === valueRef.current) return true;

      if (
        (opt.options as DefaultOptionType[] | undefined)?.find(
          (subOpt: DefaultOptionType) => subOpt.id === valueRef.current,
        )
      )
        return true;

      return false;
    }) as DefaultOptionType | undefined;

    if (selectedOpt?.options) {
      selectedOpt = (selectedOpt.options as DefaultOptionType[]).find(
        (opt: DefaultOptionType) => opt.id === valueRef.current,
      );
    }
    const label =
      dataLabelFn?.(selectedOpt || {}) ||
      selectedOpt?.label ||
      valueRef.current ||
      "";

    return <Input value={String(label)} />;
  }

  return (
    <Select
      showSearch
      {...selectOnlyProps}
      options={selectOptions}
      value={valueRef.current}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      disabled={componentDisabled || disabled}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      onChange={handleChange}
      onFocus={handleFocus}
      onPopupScroll={handleScroll}
    />
  );
};
/* eslint-enable @typescript-eslint/no-explicit-any */
