import React from "react";
import {
  Col,
  DatePicker,
  Divider,
  Form,
  InputNumber,
  Input,
  Radio,
  Row,
  Skeleton,
  theme,
  Spin,
} from "antd";
import dayjs from "dayjs";

import { FixedAssetAddition } from "types/FixedAssetAddition";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { AdditionFormValues, UseAddition, useAddition } from "../hooks";
import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";
import { validateFixedAssetAddition } from "api/FixedAssetAdditions";
import {
  assetClassificationKeys,
  assetRegistryKeys,
  fixedAssetKeys,
} from "tokens/query-keys";
import { getAssetClassifications } from "api/budgets";
import { AssetClassification } from "types/AssetClassification";
import i18next from "i18next";
import { getFixedAssets } from "api/FixedAssets";
import { FixedAsset } from "types/FixedAsset";
import { getAssetRegistries } from "api/AssetRegistry";
import { AssetRegistry } from "types/AssetRegistry";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { SubmitButton } from "../SubmitButton";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";

interface GeneralFormProps {
  record: FixedAssetAddition;
  mutation: UseMutationResult<
    FixedAssetAddition,
    AxiosError<ApiError>,
    HTMLFormElement,
    unknown
  >;
  children?: React.ReactNode | React.ReactNode[];
  renderListRegistryComponent?: (
    registry: AssetRegistry,
  ) => React.ReactNode | React.ReactNode[];
  renderCalculateDepreciationComponent?: (
    registry: AssetRegistry,
  ) => React.ReactNode | React.ReactNode[];
  additionHook?: UseAddition;
  additionType?: string;
}

const GeneralForm = ({
  record,
  mutation,
  children,
  ...props
}: GeneralFormProps) => {
  const { t } = useTranslation();
  const additionForm = props.additionHook || useAddition();
  const form = additionForm.form;

  const { token } = theme.useToken();

  const mainRegistry =
    additionForm.mainAssetRegistry.query.data || record.asset_registry;
  let additionStartDate = mainRegistry?.start_depreciation_date
    ? dayjs(mainRegistry.start_depreciation_date)
    : dayjs();

  if (additionStartDate.isBefore(dayjs())) {
    additionStartDate = dayjs();
  }

  let submitBtnText = t("fixed_asset_additions.buttons.save_and_merge");
  if (props.additionType === "link_bill") {
    submitBtnText = t("fixed_asset_additions.buttons.save_and_link_bill");
  } else if (props.additionType === "create_bill") {
    submitBtnText = t("fixed_asset_additions.buttons.save_and_create_bill");
  }

  const formComponent = (
    <Form
      disabled={!!additionForm.isDisabled}
      layout="horizontal"
      name="additionMerge"
      className="label-light"
      form={form}
      onFinish={(values: AdditionFormValues) =>
        additionForm.handleCreateAddition(values, mutation)
      }
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      style={{
        fontSize: token.fontSizeSM,
        fontWeight: "normal",
      }}
      autoComplete="off"
      colon={false}
      validateMessages={{
        required: t("activerecord.errors.messages.blank"),
      }}
      requiredMark={(
        label: React.ReactNode,
        { required }: { required: boolean },
      ) => (
        <>
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
        </>
      )}
    >
      <Form.Item name={["fixed_asset_addition", "status"]}>
        <Input type="hidden" value="" />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label={t("activerecord.attributes.fixed_asset_addition.reference")}
            name={["fixed_asset_addition", "reference"]}
            key="fixed_asset_addition_reference"
            initialValue={record?.reference}
            rules={[
              {
                required: true,
              },
              {
                validator: async (_, value: string) => {
                  const req = {
                    id: record?.id,
                    fixed_asset_addition: {
                      reference: value,
                    },
                  };
                  const resp = await validateFixedAssetAddition(req);

                  if (resp.errors?.reference)
                    return Promise.reject(
                      new Error(
                        resp.errors?.reference.join(", ") ||
                          "Invalid reference",
                      ),
                    );
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("activerecord.assets.classification.label")}
            name="asset_classification_id"
            key="asset-classification"
            initialValue={
              additionForm.assetClassification.assetClassificationID
            }
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record.asset_classification]}
              onChange={additionForm.assetClassification.handleChange}
              queryKeyObject={assetClassificationKeys}
              queryFn={getAssetClassifications}
              dataLabelFn={(cls: AssetClassification) =>
                i18next.language === "en" ? cls.english_name : cls.arabic_name
              }
            />
          </Form.Item>
          <Form.Item
            label={t("activerecord.attributes.fixed_asset_addition.asset_name")}
            name="asset_name"
            key="asset-name"
            initialValue={additionForm.fixedAsset.assetID}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record?.fixed_asset]}
              disabled={additionForm.fixedAsset.isDisabled}
              onChange={additionForm.fixedAsset.handleChange}
              queryKeyObject={fixedAssetKeys}
              filters={{
                "q[asset_classification_id_eq]":
                  additionForm.assetClassification.assetClassificationID,
              }}
              queryFn={getFixedAssets}
              dataLabelFn={(asset: FixedAsset) =>
                i18next.language === "en" ? asset.en_name : asset.name
              }
            />
          </Form.Item>
          <Form.Item
            label={t(
              "activerecord.attributes.fixed_asset_addition.asset_reference",
            )}
            name={["fixed_asset_addition", "asset_registry_id"]}
            key="asset-registry"
            initialValue={additionForm.mainAssetRegistry.mainAssetRegistry}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <SelectWithSearch
              initialOpts={[record?.asset_registry]}
              disabled={additionForm.mainAssetRegistry.isDisabled}
              onChange={additionForm.mainAssetRegistry.handleChange}
              filters={{
                "q[fixed_asset_id_eq]": additionForm.fixedAsset.assetID,
              }}
              queryKeyObject={assetRegistryKeys}
              queryFn={getAssetRegistries}
              dataLabelFn={(registry: AssetRegistry) => registry.sku}
            />
          </Form.Item>
          <Form.Item
            label={t(
              "activerecord.attributes.fixed_asset_addition.addition_date",
            )}
            name={["fixed_asset_addition", "date"]}
            initialValue={record?.date && dayjs(record?.date)}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              defaultPickerValue={additionStartDate}
              disabledDate={(current) => {
                if (additionForm.mainAssetRegistry.query.isLoading) {
                  return false;
                }

                const assetRegistry =
                  additionForm.mainAssetRegistry.query.data ||
                  record?.asset_registry;
                const endDay =
                  assetRegistry?.start_depreciation_date &&
                  dayjs(assetRegistry.start_depreciation_date);

                if (!endDay) return true;
                return current && current < endDay;
              }}
            />
          </Form.Item>
          <Form.Item
            initialValue={record?.description}
            label={t(
              "activerecord.attributes.fixed_asset_addition.description",
            )}
            name={["fixed_asset_addition", "description"]}
            rules={[
              {
                validator: async (_, value: string) => {
                  if (value && value.length > 255) {
                    return Promise.reject(
                      new Error(t("errors.messages.max_255_allowed")),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea rows={4} autoSize={false} />
          </Form.Item>
          {children}
        </Col>

        <Col span={12}>
          {additionForm.mainAssetRegistry.query.isLoading ? (
            <Skeleton />
          ) : (
            additionForm.mainAssetRegistry.query.data?.id && (
              <>
                <AssetRegistryShow
                  assetRegistry={additionForm.mainAssetRegistry.query.data}
                  title={t("fixed_asset_additions.layout.main_asset")}
                />
                {additionForm.mainAssetRegistry.query.data &&
                  props.renderListRegistryComponent?.(
                    additionForm.mainAssetRegistry.query.data,
                  )}
              </>
            )
          )}
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t(
              "activerecord.attributes.fixed_asset_addition.increment_life_cycle",
            )}
          </Divider>
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Form.Item
          hidden
          name={["fixed_asset_addition", "life_cycle_type"]}
          initialValue={record?.life_cycle_type || "years"}
        >
          <Input type="hidden" value="" />
        </Form.Item>
        <Col span={12}>
          <Form.Item
            name="lifecycle"
            wrapperCol={{ span: 20, offset: 8 }}
            className="radio-checkbox"
            initialValue={additionForm.lifeCycle.lifeCycle}
            hidden
          >
            <Radio.Group
              onChange={additionForm.lifeCycle.handleChange}
              options={[
                {
                  label: t(
                    "activerecord.attributes.fixed_asset_addition.life_cycle_types.new_date_of_life_cycle",
                  ),
                  value: "new_date_of_life_cycle",
                },
                {
                  label: t(
                    "activerecord.attributes.fixed_asset_addition.life_cycle_types.add_to_life_cycle",
                  ),
                  value: "add_to_life_cycle",
                },
              ]}
            />
          </Form.Item>
          {additionForm.lifeCycle.lifeCycle === "new_date_of_life_cycle" ? (
            <Form.Item
              initialValue={record?.life_cycle && dayjs(record?.life_cycle)}
              name={["fixed_asset_addition", "life_cycle"]}
              wrapperCol={{ span: 20, offset: 8 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultPickerValue={
                  mainRegistry?.life_cycle_start
                    ? dayjs(mainRegistry.life_cycle_start)
                    : dayjs().endOf("day")
                }
                disabledDate={(current) => {
                  if (additionForm.mainAssetRegistry.query.isLoading) {
                    return false;
                  }

                  const endDay = mainRegistry?.life_cycle_start
                    ? dayjs(mainRegistry.life_cycle_start)
                    : dayjs().endOf("day");
                  return current && current < endDay;
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={["fixed_asset_addition", "life_cycle"]}
              initialValue={
                additionForm.lifeCycle.lifeCycle !== "new_date_of_life_cycle" &&
                record.life_cycle
              }
              wrapperCol={{ span: 20, offset: 8 }}
              rules={[
                {
                  validator: async (_, value: string) => {
                    if (!value || Number(value) > 0) {
                      return Promise.resolve();
                    } else
                      return Promise.reject(
                        new Error(
                          t("activerecord.errors.messages.greater_than"),
                        ),
                      );
                  },
                },
              ]}
            >
              <InputNumber addonAfter={t("dropdowns.years")} precision={0} />
            </Form.Item>
          )}
        </Col>
      </Row>
      {additionForm.mainAssetRegistry.query.data &&
        props.renderCalculateDepreciationComponent?.(
          additionForm.mainAssetRegistry.query.data,
        )}
      <SubmitButton buttonText={submitBtnText} />
    </Form>
  );

  if (mutation.isLoading && !mutation.isError)
    return <Spin size="large">{formComponent}</Spin>;

  return formComponent;
};

export default GeneralForm;
