import React from "react";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Grid,
  Row,
  Space,
  message,
} from "antd";
import { usePermission } from "hooks/index";
import { useTranslation } from "react-i18next";
import RecordContext from "contexts/RecordContext";
import { FixedAssetAddition } from "types/FixedAssetAddition";

export const SubmitButton = ({ buttonText }: { buttonText?: string }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const permissions = usePermission("fixedAssetAddition");
  const [notificationApi, notificationCtxHolder] = message.useMessage();

  const handleValidation = () => {
    form.validateFields().catch((err) => {
      console.log(err);
      notificationApi.error(t("fixed_asset_additions.layout.form.error"));
    });
  };

  const { componentDisabled } = ConfigProvider.useConfig();
  const screens = Grid.useBreakpoint();
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const isEditable =
    permissions?.write &&
    (record.status === "draft" || record.status === "awaiting");

  if (componentDisabled) {
    return (
      <Row>
        <Col
          sm={16}
          xs={24}
          offset={screens.xs ? 0 : 8}
          style={{ marginTop: 20 }}
        >
          <Space>
            {isEditable && (
              <a href="/tenant/fixed_asset_additions/edit">
                <Button type="primary" disabled={false}>
                  {t("forms.buttons.edit")}
                </Button>
              </a>
            )}
            <a href="/tenant/fixed_asset_additions">
              <Button htmlType="button" disabled={false}>
                {t("forms.buttons.cancel")}
              </Button>
            </a>
          </Space>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {notificationCtxHolder}
      <Form.Item
        wrapperCol={{ offset: screens.xs ? 0 : 8, span: screens.xs ? 24 : 16 }}
        style={{ marginTop: 20 }}
      >
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              handleValidation();
              form.setFieldValue(["fixed_asset_addition", "status"], "draft");
            }}
          >
            {t("fixed_asset_additions.buttons.save_as_draft")}
          </Button>
          {permissions.approve ? (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                handleValidation();
                form.setFieldValue(
                  ["fixed_asset_addition", "status"],
                  "completed",
                );
              }}
            >
              {buttonText || t("forms.buttons.approve")}
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() =>
                form.setFieldValue(
                  ["fixed_asset_addition", "status"],
                  "awaiting",
                )
              }
            >
              {t("forms.buttons.save")}
            </Button>
          )}
          <a href="/tenant/fixed_asset_additions">
            <Button htmlType="button">{t("forms.buttons.cancel")}</Button>
          </a>
        </Space>
      </Form.Item>
    </>
  );
};
