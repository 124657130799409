import React from "react";
import { AssetRegistries, AssetRegistry } from "types/AssetRegistry";
import { CalculateDeprecation } from "../CalculateDepreciation";
import { Form, Spin } from "antd";
import { uniqBy } from "lodash";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "tokens/query-keys";
import { getAssetRegistries } from "api/AssetRegistry";
import RecordContext from "contexts/RecordContext";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import DisabledContext from "antd/lib/config-provider/DisabledContext";

interface BillWatch {
  transactionable_id: number;
  transactionable_type: string;
}

interface AssetRegistryWatch {
  asset_registry: AssetRegistry;
}

export const DepreciationDetails = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billWatch = Form.useWatch<BillWatch>(
    ["fixed_asset_addition", "fixed_asset_addition_transactions_attributes", 0],
    form,
  );
  const registryWatch = Form.useWatch<AssetRegistryWatch>(
    ["asset_registry"],
    form,
  );
  const registries = React.useRef([mainRegistry]);
  const componentDisabled = React.useContext(DisabledContext);

  const filters = {
    "q[commercial_document_id_eq]": billWatch?.transactionable_id,
    "q[commercial_document_type_eq]": "Bill",
    show_all: componentDisabled,
  };
  const registryQuery = useQuery<AssetRegistries>({
    queryKey: assetRegistryKeys.list?.(filters),
    queryFn: () => {
      if (
        !billWatch?.transactionable_id ||
        billWatch?.transactionable_type !== "Bill"
      )
        return [];

      return getAssetRegistries({ filters: filters });
    },
  });
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const depreciationTransaction =
    record?.fixed_asset_addition_transactions?.find(
      (tr) => tr.transactionable_type === "Depreciation",
    );

  if (registryQuery.data) {
    registries.current = uniqBy(
      [mainRegistry, ...registryQuery.data],
      "id",
    ) as AssetRegistries;
  } else {
    registries.current = [mainRegistry];
  }

  React.useEffect(() => {
    if (!billWatch) return;

    registryQuery.refetch().catch((err) => {
      console.error(err);
    });
  }, [form, billWatch, registryWatch]);

  const depreciationComponent = (
    <CalculateDeprecation
      registries={registries.current}
      depreciationID={depreciationTransaction?.transactionable_id}
    />
  );
  if (registryQuery.isLoading)
    return <Spin size="small">{depreciationComponent}</Spin>;

  return depreciationComponent;
};
