import { Button, Card, DatePicker, Form, Input, Modal } from "antd";
import { updateAssetRegistry } from "api/AssetRegistry";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AssetRegistry } from "types/AssetRegistry";

import dayjs from "dayjs";
import { assetRegistryKeys } from "tokens/query-keys";

const FillReceiveDate = ({
  assetRegistry,
  onChange,
}: {
  assetRegistry: AssetRegistry;
  onChange?: (registry: AssetRegistry) => void;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const startDepreciationDate = React.useRef<string>();
  const form = Form.useFormInstance();
  const queryClient = useQueryClient();

  const registryMuttation = useMutation({
    mutationFn: (data: { start_depreciation_date: string }) => {
      return updateAssetRegistry({
        id: assetRegistry.id,
        ...data,
      });
    },
    onSuccess: (response: AssetRegistry) => {
      setIsModalOpen(false);
      queryClient
        .invalidateQueries({
          queryKey: assetRegistryKeys.detail?.(response.id),
        })
        .catch((err) => console.log(err));
      onChange?.(response);

      form.setFieldValue("depreciation_calculated", "recalculate");
    },
  });

  const updateRegistry = () => {
    form.setFieldValue(
      ["assetRegistry", assetRegistry.id, "start_depreciation_date"],
      startDepreciationDate.current,
    );

    form
      .validateFields(
        [["assetRegistry", assetRegistry.id, "start_depreciation_date"]],
        { recursive: true },
      )
      .catch((err: { errorFields: { errors: string[] }[] }) => {
        console.log(err);
        if (!err.errorFields) return;

        if (err.errorFields.length === 0) return;

        const registryErrorField = document.querySelector(
          `#registry-${assetRegistry.id}-error`,
        );
        if (!registryErrorField) return;

        registryErrorField.innerHTML = err.errorFields[0]?.errors.join(", ");
      });

    if (startDepreciationDate.current === undefined) return;

    registryMuttation.mutate({
      start_depreciation_date: startDepreciationDate.current,
    });
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)} size="small">
        {t("forms.buttons.fill")}
      </Button>
      <Form.Item
        name={["assetRegistry", assetRegistry.id, "start_depreciation_date"]}
        rules={[{ required: true }]}
        initialValue={startDepreciationDate.current}
        hidden
      >
        <Input type="hidden" />
      </Form.Item>
      <Modal
        open={isModalOpen}
        onOk={updateRegistry}
        onCancel={() => setIsModalOpen(false)}
        title={t("activerecord.attributes.fixed_asset.received_date")}
        width="50%"
      >
        <Card>
          <DatePicker
            style={{ width: "60%" }}
            disabledDate={(current) => {
              if (!assetRegistry.purchase_date) return false;

              return current.isBefore(dayjs(assetRegistry.purchase_date));
            }}
            onChange={(_dateFormat, date: string | string[]) => {
              const errorDom = document.querySelector(
                `#registry-${assetRegistry.id}-error`,
              );

              if (!errorDom) return;

              errorDom.innerHTML = "";

              if (Array.isArray(date)) startDepreciationDate.current = date[0];
              else startDepreciationDate.current = date;
            }}
          />
          <div
            style={{ color: "#e13659", boxSizing: "border-box" }}
            id={`registry-${assetRegistry.id}-error`}
          ></div>
        </Card>
      </Modal>
    </>
  );
};

export { FillReceiveDate };
