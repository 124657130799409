import { Form, FormInstance, RadioChangeEvent } from "antd";
import { getAssetRegistry } from "api/AssetRegistry";
import { assetRegistryKeys } from "tokens/query-keys";
import { useContext, useEffect, useState } from "react";
import { UseMutationResult, UseQueryResult, useQuery } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import dayjs, { Dayjs } from "dayjs";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import { handleAssetChange, handleClassificationChange } from "../helpers";
import RecordContext from "contexts/RecordContext";
import { AssetRegistry } from "types/AssetRegistry";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";
import { DefaultOptionType } from "antd/es/select";

export interface AdditionFormValues {
  fixed_asset_addition: Record<
    string,
    string | number | Dayjs | undefined | null
  >;
  bill: Record<string, string | number | undefined | null>;
  depreciation: Record<string, string | number>;
}

export interface UseAddition {
  assetClassification: {
    assetClassificationID: number | null;
    setAssetClassificationID: (value: number) => void;
    handleChange: (value: string) => void;
  };
  fixedAsset: {
    assetID: number | null;
    setAssetID: (value: number) => void;
    handleChange: (value: string) => void;
    isDisabled: boolean;
  };
  lifeCycle: {
    lifeCycle: string;
    setLifeCycle: (value: string) => void;
    handleChange: (event: RadioChangeEvent) => void;
  };
  mainAssetRegistry: {
    mainAssetRegistry: number | null;
    setMainAssetRegistry: (value: number) => void;
    query: UseQueryResult<AssetRegistry | undefined, unknown>;
    handleChange: (
      value: string,
      options: DefaultOptionType | DefaultOptionType[],
    ) => void;
    isDisabled: boolean;
  };
  mergeRegistry: {
    handleChange: (
      value: string,
      options: DefaultOptionType | DefaultOptionType[],
    ) => void;
  };
  handleCreateAddition: (
    form: AdditionFormValues,
    mutation: UseMutationResult<
      FixedAssetAddition,
      AxiosError<ApiError>,
      HTMLFormElement,
      unknown
    >,
  ) => void;
  form: FormInstance;
  isDisabled: boolean;
  additionDateWatch: Dayjs | undefined;
}

const initLifeCycle = (_record: FixedAssetAddition): string => {
  return "add_to_life_cycle";
};

export const useAddition = () => {
  const record = useContext(RecordContext) as unknown as FixedAssetAddition;
  const [formState, setFormState] = useState({
    assetClassificationID: record?.asset_classification?.id || null,
    assetID: record?.fixed_asset?.id || null,
    lifeCycle: initLifeCycle(record),
    mainRegistry: record?.asset_registry_id || null,
  });
  const isAssetDisabled = !formState.assetClassificationID;
  const isRegistryDisabled = isAssetDisabled || !formState.assetID;

  const [newForm] = Form.useForm();
  const form = Form.useFormInstance() || newForm;
  const additionDate = Form.useWatch<Dayjs | undefined>(
    ["fixed_asset_addition", "date"],
    form,
  );
  const registryData = Form.useWatch<
    Record<string, { start_depreciation_date: Dayjs | undefined }>
  >(["asset_registry"], form);
  const componentDisabled = useContext(DisabledContext);

  const main = useQuery({
    queryKey: assetRegistryKeys.details?.(
      Number(formState.mainRegistry),
      additionDate,
    ),
    queryFn: () => {
      if (!formState.mainRegistry) return;

      const dateString =
        additionDate && dayjs(additionDate).format("YYYY-MM-DD");
      return getAssetRegistry(formState.mainRegistry, {
        depreciation_date: dateString,
      });
    },
  });

  useEffect(() => {
    if (!formState.mainRegistry) {
      return;
    }

    if (
      !componentDisabled &&
      form.getFieldValue("lifecycle") === "new_date_of_life_cycle"
    )
      form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");

    main.refetch().catch(() => {
      console.log("Error");
    });
  }, [formState.mainRegistry, registryData]);

  const handleChangeAssetClassification = (value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      assetClassificationID: Number(value),
      assetID: null,
      mainRegistry: null,
    }));
    handleClassificationChange(form);
    form.setFieldValue(["fixed_asset_addition", "date"], "");
  };

  const handleChangeAsset = (value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      assetID: Number(value),
      mainRegistry: null,
    }));
    handleAssetChange(form);
    form.setFieldValue(["fixed_asset_addition", "date"], "");
  };

  const showAssetRegistry = (registryType: string) => (value: string) => {
    const numberValue = Number(value);
    if (registryType === "main") {
      form.setFieldValue(["fixed_asset_addition", "date"], "");
      setFormState((prevState) => ({
        ...prevState,
        mainRegistry: numberValue,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        mergeRegistry: numberValue,
      }));
    }
  };

  const handleLifeCycleChange = (event: RadioChangeEvent) => {
    form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");

    if (event.target.value === "new_date_of_life_cycle") {
      form.setFieldValue(
        ["fixed_asset_addition", "life_cycle_type"],
        "new_date_of_life_cycle",
      );
    } else {
      form.setFieldValue(["fixed_asset_addition", "life_cycle_type"], "years");
    }

    setFormState((prevState) => ({
      ...prevState,
      lifeCycle: (event.target.value as string) || "",
    }));
  };

  const handleCreateAddition = (
    formValues: AdditionFormValues,
    mutation: UseMutationResult<
      FixedAssetAddition,
      AxiosError<ApiError>,
      HTMLFormElement,
      unknown
    >,
  ) => {
    const lifeCycleData =
      form.getFieldValue("lifecycle") === "new_date_of_life_cycle"
        ? (
            formValues.fixed_asset_addition.life_cycle as Dayjs | undefined
          )?.format("YYYY-MM-DD")
        : (formValues.fixed_asset_addition.life_cycle as string | undefined);
    let billAttributes = {};

    if (formValues.bill) {
      billAttributes = {
        ...formValues.bill,
        issue_date: (formValues.bill.issue_date as Dayjs | undefined)?.format(
          "YYYY-MM-DD",
        ),
        due_date: (formValues.bill.due_date as Dayjs | undefined)?.format(
          "YYYY-MM-DD",
        ),
        supply_date: (formValues.bill.supply_date as Dayjs | undefined)?.format(
          "YYYY-MM-DD",
        ),
      };
    }

    const values = {
      fixed_asset_addition: {
        ...formValues.fixed_asset_addition,
        date: (formValues.fixed_asset_addition.date as Dayjs).format(
          "YYYY-MM-DD",
        ),
        life_cycle: lifeCycleData,
      },
      depreciation: formValues.depreciation,
      bill: billAttributes,
    };

    mutation.mutate(values as unknown as HTMLFormElement);
  };

  const additionState: UseAddition = {
    assetClassification: {
      assetClassificationID: formState.assetClassificationID
        ? Number(formState.assetClassificationID)
        : null,
      setAssetClassificationID: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          assetClassificationID: value,
        }));
      },
      handleChange: handleChangeAssetClassification,
    },
    fixedAsset: {
      assetID: formState.assetID ? Number(formState.assetID) : null,
      setAssetID: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          assetID: value,
        }));
      },
      handleChange: handleChangeAsset,
      isDisabled: isAssetDisabled,
    },
    lifeCycle: {
      lifeCycle: formState.lifeCycle,
      setLifeCycle: (value: string) => {
        setFormState((prevState) => ({
          ...prevState,
          lifeCycle: value,
        }));
      },
      handleChange: handleLifeCycleChange,
    },
    mainAssetRegistry: {
      mainAssetRegistry: formState.mainRegistry
        ? Number(formState.mainRegistry)
        : null,
      setMainAssetRegistry: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          mainRegistry: value,
        }));
      },
      query: main,
      handleChange: showAssetRegistry("main"),
      isDisabled: isRegistryDisabled,
    },
    mergeRegistry: {
      handleChange: showAssetRegistry("merge"),
    },
    handleCreateAddition,
    form,
    isDisabled: componentDisabled,
    additionDateWatch: additionDate,
  };

  return additionState;
};
