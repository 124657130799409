import { useTranslation } from 'react-i18next'
import QServiceLayout from 'components/layout/QServiceLayout';
import { Row, Col, Typography, Flex, Divider } from 'antd';
import { trainingInfoItems } from 'tokens/additional_services';
import InfoItem from 'components/tenant/additional_services/InfoItem';
import ServiceRequestButton from 'hooks/additional_services';

const { Title, Paragraph } = Typography;

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.professional_services'),
      },
      {
          title: t('activerecord.attributes.layout.training_service'),
      },
  ]
}

const Training = () => {
  const { t } = useTranslation();
  const breadCrumbData = useBreadCrumbData();
  
  return (
    <QServiceLayout title={t('activerecord.attributes.layout.training_service')} actionBtns={null} breadCrumbData={breadCrumbData}>
      <Row gutter={[32, 32]}>
        <Col md={12}>
          <Flex vertical justify='center' style={{ height: '100%' }}>
            <Title level={4}>{t('activerecord.attributes.professional_services.training.title')}</Title>
            <Paragraph style={{ textAlign: 'justify' }}>
              {t('activerecord.attributes.professional_services.training.description')}
            </Paragraph>
            <ServiceRequestButton
              serviceType="Training"
              title={t('activerecord.attributes.professional_services.training.title')}
              actionLabel={t('activerecord.attributes.professional_services.training.action')}
              successMessage={t("activerecord.attributes.vat_services.setup.request_successfully_submitted")}
              description={t("activerecord.attributes.vat_services.setup.request_submitted_description")}
            />
          </Flex>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[32, 32]}>
        {trainingInfoItems.map((item, idx) => (
          <InfoItem key={idx} title={`${idx + 1}. ${t(item.title)}`} content={t(item.content)} Icon={item.Icon} />
        ))}
      </Row>
    </QServiceLayout>
  )
}

export default Training