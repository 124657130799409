import { useEffect, useState } from 'react'
import { Form, Input, Flex, Button, message, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next';
import QPageLayout from 'components/layout/QPageLayout';
import BackButton from 'components/layout/BackButton';
import axios from 'axios';
import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import { getUnAssignedDimensionValues } from 'api/dimensions';
import { DimensionValueResponse, DimensionType, DimensionValue } from 'types/dimensions';

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: <a href="/tenant/dimensions">{t('activerecord.attributes.dimension.name')}</a>,
      },
      {
          title: t('activerecord.attributes.dimension_value.new'),
      },
  ]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 4 }
    
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 8 },
  },
};

interface ParentValue {
  label: string;
  value: number;
}
const NewDimensionValue = () => {
  const { t } = useTranslation();
  const [ form ] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [parentValues, setParentValues] = useState<ParentValue[]>([]);
  const dimensionID = (/\d+/.exec(location.pathname))?.[0]
  const [isSelectingParent, setIsSelectingParent] = useState<boolean>(false);
  const [parentID, setParentID] = useState<string | null>(null);
  const breadCrumbData = useBreadCrumbData();
 
  const nextCodeQuery = useQuery(['nextCode', parentID], async () => (await axios.get<{next_code: string, dimension: DimensionType}>(`/tenant/dimensions/${dimensionID}/values/next_code.json?parent_id=${parentID}`)).data);

  const dimensionParentValuesQuery = useInfiniteQuery<DimensionValueResponse>(['parenDimensions'], getUnAssignedDimensionValues.bind(null, { dimensionID: dimensionID ?? '' }), {
    getNextPageParam: (lastPage) => lastPage.pagination.next_page,
    enabled: isSelectingParent
  })

useEffect(() => {
  if (dimensionParentValuesQuery.data) {
    const pages = dimensionParentValuesQuery.data.pages;
    const transformedParentValues: ParentValue[] = pages
      .flatMap((page: { records: DimensionValue[] }) =>
        page.records.map(record => ({ label: `${(record as {code:string}).code} - ${i18next.language === 'en'? (record as {name_en :string}).name_en : (record as {name_ar :string}).name_ar}`, value: (record as {id:number}).id }))
      );

    setParentValues(() => transformedParentValues);
  }
}, [dimensionParentValuesQuery.data]);

  const mutation = useMutation({
    mutationFn: (values) => {
      return axios.post(`/tenant/dimensions/${dimensionID}/values.json`, values)
    },
    onSuccess: () => {
      window.go_back();
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    }
  })
  
  useEffect(() => {
    if(nextCodeQuery.data) {
      form.setFieldValue('code', nextCodeQuery.data.next_code);
      form.setFieldValue('dimension_name', i18next.language === 'en'
          ? (nextCodeQuery.data.dimension as { name_en: string }).name_en
          : (nextCodeQuery.data.dimension as { name_ar: string }).name_ar);
      form.setFieldValue('dimension_code', (nextCodeQuery.data.dimension as {code:string }).code);
    }
  }, [nextCodeQuery.data])
  
  return (
    <QPageLayout messageHolder={contextHolder} title={t('activerecord.attributes.dimension_value.new')} actionBtns={
      <BackButton />
    } breadCrumbData={breadCrumbData} >
      <Form
        labelWrap
        form={form}
        name="code"
        onFinish={mutation.mutate}
        scrollToFirstError
        validateMessages={{
          required: t("activerecord.attributes.dimension.create_code.errors.required") + " ${label}"
        }}
        {...formItemLayout}
      >
          <Form.Item
            name="dimension_name"
            label={t("activerecord.attributes.dimension_value.dimension_code_name")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="dimension_code"
            label={t("activerecord.attributes.dimension_value.dimension_reference")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="parent_id"
            label={t("activerecord.attributes.dimension_value.parent_dimension_value")}
          >
            <Select
                onPopupScroll={() => { if(dimensionParentValuesQuery.hasNextPage) { void dimensionParentValuesQuery.fetchNextPage()}}}             
                options={[
                  { label: t('activerecord.attributes.dimension_value.value_types.standalone'), value: null },
                    ...parentValues,
                  ]}
                loading={dimensionParentValuesQuery.isLoading}
                onClick={() => setIsSelectingParent(true)}
                onChange={(value: string) => setParentID(value)}
                placeholder={t('activerecord.attributes.dimension_value.value_types.standalone')}
                value={null}
            />
          </Form.Item>
          <Form.Item
            name="code"
            label={t("activerecord.attributes.dimension_value.id")}
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="name_ar"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_ar")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item
            name="name_en"
            rules={[
              {
                required: true
              }
            ]}
            label={t("activerecord.attributes.dimension.create_code.name_en")}
          >
            <Input maxLength={25} />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { offset: 0 }, lg: { offset: 12 } }}>
            <Flex gap="small" justify='end'>
              <Button type="primary" disabled={mutation.isLoading || mutation.isSuccess ? true : false} htmlType="submit">
                {t("forms.buttons.save")}
              </Button>
              <Button danger href='/tenant/dimensions'>
                {t("forms.buttons.cancel")}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
    </QPageLayout>
  )
}

export default NewDimensionValue