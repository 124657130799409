/* eslint-disable */
// @ts-nocheck
import { createContext } from "react";
import type { ItemData } from "types/layout";

export default createContext<{
  sideMenuData: ItemData[];
  currentActiveItem: string;
  currentActiveMenu: string;
  isMobileRequest: boolean;
  currentUser: {
    label: string;
    href: string;
  };
  organizationName: string;
  comments: any;
  tasks: any;
  isOnBoard: boolean;
  canSendSuggestions: boolean;
}>(null);
/* eslint-enable */
