import axios from "axios";
import {
  AssetRegistries,
  AssetRegistry,
  AssetRegistriesResponse,
} from "types/AssetRegistry";

export const getAssetRegistries = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: {
  search?: string;
  page?: number;
  pageSize?: number;
  sorter?: string;
  filters?: Record<
    string,
    string | number | boolean | Record<string, string | number | boolean>
  >;
}): Promise<AssetRegistries> => {
  const { data } = await axios.get<AssetRegistriesResponse>(
    "/tenant/asset_registers/fetch_all.json",
    {
      params: {
        "q[sku_cont]": search,
        "q[s]": sorter || "created_at desc",
        page,
        per_page: pageSize || 20,
        ...filters,
      },
    },
  );
  return data.asset_registries;
};

export const getAssetRegistry = async (
  assetRegistryID: number,
  params?: Record<
    string,
    | string
    | number
    | boolean
    | Record<string, string | number | boolean>
    | undefined
  >,
): Promise<AssetRegistry> => {
  const { data } = await axios.get<AssetRegistry>(
    `/tenant/asset_registers/${assetRegistryID}.json`,
    {
      params: params,
    },
  );

  return data;
};

export const updateAssetRegistry = async (registryParams: {
  id: number | string;
  start_depreciation_date: string;
}): Promise<AssetRegistry> => {
  const { data } = await axios.put<AssetRegistry>(
    `/tenant/asset_registers/${registryParams.id}.json`,
    { asset_registry: registryParams },
  );

  return data;
};
