import { QueryKeyType } from "types/query-keys";

type FilterType = Record<
  string,
  string | number | Record<string, string | number>
>;

export const fixedAssetAdditionKeys: QueryKeyType = {
  all: ["fixed-asset-additions"],
  lists: () => [...fixedAssetAdditionKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(fixedAssetAdditionKeys.lists ?? (() => fixedAssetAdditionKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(fixedAssetAdditionKeys.list ?? (() => fixedAssetAdditionKeys.all))(
      filters,
    ),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [
    ...fixedAssetAdditionKeys.all,
    "details",
    id,
  ],
};

export const assetRegistryKeys: QueryKeyType = {
  all: ["asset-registry"],
  lists: () => [...assetRegistryKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(assetRegistryKeys.lists ?? (() => assetRegistryKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(assetRegistryKeys.list ?? (() => assetRegistryKeys.all))(filters),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [...assetRegistryKeys.all, "details", id],
  details: (id: number | string, params?: Record<string, string | number>) => [
    ...(assetRegistryKeys.detail?.(id) ?? [
      ...assetRegistryKeys.all,
      "details",
      id,
    ]),
    params,
  ],
};

export const assetClassificationKeys: QueryKeyType = {
  all: ["asset-classification"],
  lists: () => [...assetClassificationKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(assetClassificationKeys.lists ?? (() => assetClassificationKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(assetClassificationKeys.list ?? (() => assetClassificationKeys.all))(
      filters,
    ),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [
    ...assetClassificationKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: Record<string, string | number>) => [
    ...(assetClassificationKeys.detail?.(id) ?? [
      ...assetClassificationKeys.all,
      "details",
      id,
    ]),
    params,
  ],
};

export const fixedAssetKeys: QueryKeyType = {
  all: ["fixed-assets"],
  lists: () => [...fixedAssetKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(fixedAssetKeys.lists ?? (() => fixedAssetKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(fixedAssetKeys.list ?? (() => fixedAssetKeys.all))(filters),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [...fixedAssetKeys.all, "details", id],
  details: (id: number | string, params?: Record<string, string | number>) => [
    ...(fixedAssetKeys.detail?.(id) ?? [...fixedAssetKeys.all, "details", id]),
    params,
  ],
};

export const billKeys: QueryKeyType = {
  all: ["bills"],
  lists: () => [...billKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(billKeys.lists ?? (() => billKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(billKeys.list ?? (() => billKeys.all))(filters),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [...billKeys.all, "details", id],
  details: (id: number | string, params?: Record<string, string | number>) => [
    ...(billKeys.detail?.(id) ?? [...billKeys.all, "details", id]),
    params,
  ],
};

export const commercialDocumentKeys: QueryKeyType = {
  all: ["commercial-documents"],
  lists: () => [...commercialDocumentKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(commercialDocumentKeys.lists ?? (() => commercialDocumentKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(commercialDocumentKeys.list ?? (() => commercialDocumentKeys.all))(
      filters,
    ),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [
    ...commercialDocumentKeys.all,
    "details",
    id,
  ],
  details: (id: number | string, params?: Record<string, string | number>) => [
    ...(commercialDocumentKeys.detail?.(id) ?? [
      ...commercialDocumentKeys.all,
      "details",
      id,
    ]),
    params,
  ],
};

export const currentUserKeys: QueryKeyType = {
  all: ["current-user"],
};

export const depreciationKeys: QueryKeyType = {
  all: ["depreciations"],
  lists: () => [...depreciationKeys.all, "lists"],
  list: (filters: FilterType) => [
    ...(depreciationKeys.lists ?? (() => depreciationKeys.all))(),
    filters,
  ],
  paginate: (
    filters: FilterType,
    pageNumber: number,
    pageSize?: number,
    sorter?: string,
  ) => [
    ...(depreciationKeys.list ?? (() => depreciationKeys.all))(filters),
    "paginate",
    pageNumber,
    pageSize ?? 10,
    sorter ?? "",
  ],
  detail: (id: number | string) => [...depreciationKeys.all, "details", id],
};
