import React, { useState } from 'react';
import { Button, Result, message, Modal } from 'antd';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface ServiceRequestButtonProps {
  serviceType: string;
  title: string;
  actionLabel: string;
  successMessage: string;
  description: string;
}

const ServiceRequestButton: React.FC<ServiceRequestButtonProps> = ({
  serviceType,
  actionLabel,
  successMessage,
  description,
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState<null | React.ReactNode>(null);
  const [messageApi, messageContext] = message.useMessage();

  const mutation = useMutation({
    mutationKey: [serviceType],
    mutationFn: async () => {
      return await axios.post('/tenant/additional_services', {
        service_type: serviceType,
      });
    },
    onSuccess: () => {
      setContent(
        <Result
          status="success"
          title={successMessage}
          subTitle={description}
          extra={[
            <Button type="primary" key="ok" onClick={() => setContent(null)}>
              {t('activerecord.attributes.vat_services.setup.ok_thanks')}
            </Button>,
          ]}
        />
      );
    },
    onError: () => {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error'),
      });
    },
  });

  return (
    <>
      {messageContext}
      <div>
        <Button type="primary" onClick={() => mutation.mutate()}>
          {actionLabel}
        </Button>
      </div>
      <Modal footer={null} open={Boolean(content)} closable={false}>
        {content}
      </Modal>
    </>
  );
};

export default ServiceRequestButton;