import axios, { AxiosError } from "axios";
import {
  FixedAssetAddition,
  FixedAssetAdditionsWithPagination,
  FixedAssetAdditionWithErrors,
} from "types/FixedAssetAddition";

export async function getFixedAssetAdditions(
  page: number,
  filters: Record<string, string | number | Record<string, string | number>>,
  pageSize: number,
  sorter: string,
) {
  const { data } = await axios.get<FixedAssetAdditionsWithPagination>(
    `/tenant/fixed_asset_additions.json`,
    {
      params: {
        page,
        per_page: pageSize,
        "q[s]": sorter,
        ...filters,
      },
    },
  );
  return data;
}

export async function getFixedAssetAddition(
  fixedAssetAdditionID: number | string,
) {
  const { data } = await axios.get<FixedAssetAddition>(
    `/tenant/fixed_asset_additions/${fixedAssetAdditionID}.json`,
  );
  return data;
}

export async function deleteFixedAssetAddition(record: FixedAssetAddition) {
  try {
    const { data } = await axios.delete<FixedAssetAddition>(
      `/tenant/fixed_asset_additions/${record.id}.json`,
    );

    return data;
  } catch (error: unknown) {
    throw (error as AxiosError)?.response?.data || error;
  }
}

export const createFixedAssetAddition = async (
  values: HTMLFormElement,
): Promise<FixedAssetAddition> => {
  const { data } = await axios.post<FixedAssetAddition>(
    "/tenant/fixed_asset_additions.json",
    values,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return data;
};

export const updateFixedAssetAddition =
  (fixedAssetAdditionID: number) =>
  async (values: HTMLFormElement): Promise<FixedAssetAddition> => {
    const { data } = await axios.put<FixedAssetAddition>(
      `/tenant/fixed_asset_additions/${fixedAssetAdditionID}.json`,
      values,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    return data;
  };

export const initFixedAssetAddition = async (): Promise<FixedAssetAddition> => {
  const { data } = await axios.get<FixedAssetAddition>(
    "/tenant/fixed_asset_additions/new.json",
  );
  return data;
};

export const validateFixedAssetAddition = async (values: {
  id: number | string;
  fixed_asset_addition: {
    reference: string;
  };
}): Promise<FixedAssetAdditionWithErrors> => {
  const { data } = await axios.post<FixedAssetAdditionWithErrors>(
    "/tenant/fixed_asset_additions/validate.json",
    values,
  );
  return data;
};
