import { useTranslation } from "react-i18next";
import { Skeleton, message } from "antd";

import QPageLayout from "components/layout/QPageLayout";
import { useMutation, useQuery } from "react-query";
import {
  createFixedAssetAddition,
  initFixedAssetAddition,
} from "api/FixedAssetAdditions";
import { AxiosError } from "axios";
import { ApiError } from "types/Error";
import BackButton from "components/layout/BackButton";
import { FixedAssetAdditionMergeForm } from "components/tenant/FixedAssetAdditions/Merge";
import { fixedAssetAdditionKeys } from "tokens/query-keys";
import RecordContext from "contexts/RecordContext";

const FixedAssetAdditionNewMerge = () => {
  const { t } = useTranslation();
  const [messageAPI, msgContext] = message.useMessage();

  const breadCrumbData = [
    {
      title: (
        <a href="/tenant/fixed_assets">
          {t("activerecord.attributes.layout.fixed_assets")}
        </a>
      ),
    },
    {
      title: (
        <a href="/tenant/fixed_asset_additions">
          {t("fixed_asset_additions.layout.title")}
        </a>
      ),
    },
    {
      title: t("fixed_asset_additions.layout.new"),
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: fixedAssetAdditionKeys.detail?.("new"),
    queryFn: () => initFixedAssetAddition(),
    cacheTime: 0,
  });

  const fixedAssetAdditionMutation = useMutation({
    mutationFn: createFixedAssetAddition,
    onSuccess: () => {
      window.location.href = `/tenant/fixed_asset_additions`;
    },
    onError: (error: AxiosError<ApiError>) => {
      messageAPI.open({
        type: "error",
        duration: 10,
        style: {
          color: "#d76d6d",
        },
        content:
          error.response?.data.message?.errors || t("forms.messages.error"),
      });
    },
  });

  return (
    <QPageLayout
      title={t("fixed_asset_additions.layout.title")}
      actionBtns={<BackButton />}
      breadCrumbData={breadCrumbData}
      messageHolder={msgContext}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <RecordContext.Provider value={data || { id: 0 }}>
          <FixedAssetAdditionMergeForm mutation={fixedAssetAdditionMutation} />
        </RecordContext.Provider>
      )}
    </QPageLayout>
  );
};

export default FixedAssetAdditionNewMerge;
